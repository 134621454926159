import {
  GridColDef,
  GridRowParams,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "../store/store";
import { Dispatch } from "react";
import { Link } from "@mui/material";
import { useGetDialog } from "./useGetDialog";
import { useNavigate } from "react-router-dom";
import { useRequestDataIfNeeded } from "./useRequestDataIfNeeded";
import { routesList } from "../constants";
import { deleteProductTypes } from "../store/asyncThunk/productThunk";

export const useProductTypesDataGridInfo = () => {
  const productTypes = useAppSelector((state) => state.productTypes);
  const deleteDialog = useGetDialog();
  const dispatch = useAppDispatch();
  const nav = useNavigate();

  useRequestDataIfNeeded(["productTypes", "products"]);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      description: "Emri i llojit te produktit",
      renderCell: (params) => (
        <Link
          underline="hover"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            nav(routesList.productTypes + `/${params.row.id}`);
          }}
        >
          {params.row.name}
        </Link>
      ),
    },
  ];

  const handleDeleteRows = (
    rows: GridRowSelectionModel,
    setSelectedRows: Dispatch<React.SetStateAction<GridRowSelectionModel>>
  ) => {
    deleteDialog.update({
      title: `Are you sure you want to delete ${rows.length} ProductTypes`,
      primaryButton: {
        label: "Yes",
        onClick: () => {
          dispatch(deleteProductTypes(rows as string[]));
          deleteDialog.close();
          setSelectedRows([]);
        },
      },
      secondaryButton: {
        label: "No",
        onClick: () => {
          deleteDialog.close();
        },
      },
    });
    deleteDialog.toggleDialog();
  };

  const handleAddInstance = () => {
    nav(routesList.addProductType);
  };

  const handleRowDoubleClick = (params: GridRowParams) => {
    nav(routesList.productTypes + `/${params.row.id}`);
  };

  return {
    gridProps: {
      rows: productTypes.list,
      columns: columns,
      handleDeleteRows: handleDeleteRows,
      handleAddInstance: handleAddInstance,
      handleRowDoubleClick: handleRowDoubleClick,
      csvFileName: "Product_types",
    },
    dialog: deleteDialog,
  };
};
