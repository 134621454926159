import * as React from "react";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";

import {
  DataGrid,
  GridColDef,
  GridFooterContainer,
  gridPageCountSelector,
  gridPaginationModelSelector,
  GridRowParams,
  GridRowSelectionModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";

import { Button, hexToRgb, Pagination } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  PrivateBrandData,
  PrivateProductData,
  RestockingData,
  SaleData,
  SlideshowData,
} from "../../packages/cmsapi";
import { Dispatch } from "react";

interface IDataTableProps {
  rows?:
    | PrivateProductData[]
    | PrivateBrandData[]
    | SaleData[]
    | RestockingData[]
    | SlideshowData[];
  columns: GridColDef[];
  handleDeleteRows?: (
    rows: GridRowSelectionModel,
    setSelectedRows: Dispatch<React.SetStateAction<GridRowSelectionModel>>
  ) => void;
  handleAddInstance?: () => void;
  handleRowDoubleClick?: (params: GridRowParams) => void;
  loading?: boolean;
  csvFileName?: string;
}

const CustomFooter = () => {
  const apiRef = useGridApiContext();
  const paginationModel = useGridSelector(apiRef, gridPaginationModelSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  return (
    <GridFooterContainer
      sx={{
        justifyContent: "flex-start",
      }}
    >
      <Pagination
        count={pageCount}
        page={paginationModel.page + 1}
        onChange={(_, value) => apiRef.current.setPage(value - 1)}
      />
    </GridFooterContainer>
  );
};

export const DataTable = ({
  rows,
  columns,
  handleDeleteRows = () => {},
  handleAddInstance = () => {},
  handleRowDoubleClick = () => {},
  loading = false,
  csvFileName = "Videomobile_info",
}: IDataTableProps) => {
  const [selectedRows, setSelectedRows] = React.useState<GridRowSelectionModel>(
    []
  );
  const getFormattedDate = () => {
    const now = new Date();
    return now.toISOString().replace(/:/g, "-");
  };

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
        "& .MuiDataGrid-root": {
          overflow: "auto",
        },
        overflow: "hidden",
      }}
    >
      <DataGrid
        rows={rows}
        // columns={[...columns, actionColumn]}
        columns={columns}
        disableRowSelectionOnClick
        checkboxSelection
        rowSelectionModel={selectedRows}
        onRowSelectionModelChange={(newSelection) => {
          setSelectedRows(newSelection);
        }}
        onRowDoubleClick={handleRowDoubleClick}
        getRowClassName={(params) => {
          if (params.row.isDeleted) {
            return "deleted-row";
          } else if (params.row.isHidden) {
            return "hidden-row";
          }
          return "";
        }}
        sx={{
          "& .deleted-row": {
            backgroundColor: "rgba(255, 0, 0, 0.1)", // Inline style for deleted rows
            "&:hover": {
              backgroundColor: "rgba(255, 0, 0, 0.2)", // Lighter red on hover
            },
          },
          "& .hidden-row": {
            backgroundColor: "rgba(255, 165, 0, 0.2)", // Orange style for hidden rows
            "&:hover": {
              backgroundColor: "rgba(255, 165, 0, 0.3)", // Lighter orange on hover
            },
          },
          "& .Mui-selected": {
            backgroundColor: "rgba(255, 235, 59, 0.5) !important",
            "&:hover": {
              backgroundColor: "rgba(255, 235, 59, 0.7) !important", // Lighter green on hover for selected rows
            },
          },
        }}
        slots={{
          toolbar: () => (
            <GridToolbarContainer
              sx={{
                display: "flex",
                justifyContent: "space-between", // Space between Quick Filter and Buttons
                paddingX: 2, // Optional: Add horizontal padding
              }}
            >
              <GridToolbarQuickFilter />
              <Box display="flex" gap={2}>
                {selectedRows.length > 0 && (
                  <Button
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={() => {
                      handleDeleteRows(selectedRows, setSelectedRows);
                    }} // Delete selected rows
                  />
                )}
                <Button
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => handleAddInstance()}
                >
                  Add Instance
                </Button>
                <GridToolbarColumnsButton />
                <GridToolbarExport
                  csvOptions={{
                    fileName: `${csvFileName}_${getFormattedDate()}`,
                    utf8WithBom: true,
                  }}
                />
              </Box>
            </GridToolbarContainer>
          ),
          footer: CustomFooter,
        }}
      />
    </Box>
  );
};
