import {
  DndContext,
  MouseSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import { Box, Button } from "@mui/material";
import { ISortableImageProps, SortableImage } from "../../Common/SortableImage";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import {
  addPicturesToProduct,
  deleteProductPictures,
  reorderProductPictures,
} from "../../../store/asyncThunk/productThunk";
import { useParams } from "react-router-dom";

export const EditProductPictures = () => {
  const { productId } = useParams();
  const productInfo = useAppSelector(
    (state) => state.productInfoMap[productId ?? ""]
  );
  const dispatch = useAppDispatch();

  const [images, setImages] = React.useState<ISortableImageProps[] | undefined>(
    productInfo?.picturesUrl?.map((url, index) => {
      return {
        id: index.toString(),
        url: url,
      } as ISortableImageProps;
    }) || []
  );

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );

  useEffect(() => {
    if (productInfo) {
      setImages(
        productInfo.picturesUrl.map((url, index) => {
          return { id: index.toString(), url: url } as ISortableImageProps;
        })
      );
    }
  }, [productInfo]);

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setImages((prevImages) => {
        if (prevImages) {
          const oldIndex = prevImages.findIndex(
            (image) => image.id === active.id
          );
          const newIndex = prevImages.findIndex(
            (image) => image.id === over.id
          );

          return arrayMove(prevImages, oldIndex, newIndex);
        }
      });
      if (productInfo && images) {
        const oldIndex = images.findIndex((image) => image.id === active.id);
        const newIndex = images.findIndex((image) => image.id === over.id);
        dispatch(
          reorderProductPictures({
            productId: productInfo.id,
            pictureUrls: arrayMove(images, oldIndex, newIndex).map(
              (image) => image.url
            ),
          })
        );
      }
    }
  };

  const handleImageDelete = (id: string) => {
    const deletedImage = images?.find((image) => image.id === id);
    if (deletedImage && productInfo) {
      dispatch(
        deleteProductPictures({
          productId: productInfo.id,
          pictureUrls: [deletedImage.url],
        })
      );
    }
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && productInfo?.id) {
      dispatch(
        addPicturesToProduct({
          productId: productInfo.id,
          files: Array.from(files),
        })
      );
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <DndContext
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        sensors={sensors}
      >
        <SortableContext
          items={images ? images.map((image) => image.id) : []}
          strategy={rectSortingStrategy}
        >
          <Box
            sx={{
              width: 600,
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
              gap: 1,
              justifyContent: "center",
              justifyItems: "center",
              overflow: "hidden",
            }}
          >
            {images?.map((image) => (
              <SortableImage
                key={image.id}
                id={image.id}
                url={image.url}
                onDelete={handleImageDelete}
              />
            ))}
          </Box>
        </SortableContext>
      </DndContext>

      <Box sx={{ margin: 5 }}>
        <input
          accept="image/*"
          style={{ display: "none" }}
          id="upload-image"
          multiple
          type="file"
          onChange={handleImageUpload}
        />
        <label htmlFor="upload-image">
          <Button variant="contained" component="span">
            Upload Images
          </Button>
        </label>
      </Box>
    </Box>
  );
};

export default EditProductPictures;
