import React, { useMemo, useReducer } from "react";
import { SaleData } from "../../../packages/cmsapi";
import { Box, Button, Grid2, TextField, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { createSale } from "../../../store/asyncThunk/productThunk";
import { useNavigate } from "react-router-dom";
import { SalesReducer } from "./SalesReducer";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { VirtualizedAutocomplete } from "../../Common";
import { useRequestDataIfNeeded } from "../../../hooks/useRequestDataIfNeeded";

const emptySaleState = { receiptId: "" } as SaleData;

export const AddSales = () => {
  const products = useAppSelector((state) => state.products);
  const productInfoMap = useAppSelector((state) => state.productInfoMap);
  const [saleInfo, saleDispatch] = useReducer(SalesReducer, emptySaleState);
  const nav = useNavigate();
  const dispatch = useAppDispatch();

  const virtualizedProductList = useMemo(() => {
    return products.list.filter((product) => !product.isDeleted);
  }, [products.list]);

  useRequestDataIfNeeded(["sales", "products"]);
  return (
    <Box
      sx={{
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Typography variant="h4" gutterBottom>
        Add Sale
      </Typography>

      <Grid2 container spacing={2} justifyContent={"center"}>
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <VirtualizedAutocomplete
            options={virtualizedProductList}
            setSelected={(suggestions) => {
              saleDispatch({
                type: "SET_FIELD",
                fieldName: "productId",
                value: suggestions[0]?.id,
              });
            }}
            selected={saleInfo.productId ? [saleInfo.productId] : []}
            isMultiple={false}
            label="Sold Product"
          />
        </Grid2>
        {/* Price */}
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <TextField
            label="Price"
            type="number"
            variant="outlined"
            fullWidth
            helperText={
              productInfoMap[saleInfo.productId]
                ? `Current product price is ${productInfoMap[saleInfo.productId].price}`
                : ""
            }
            value={saleInfo.price || ""}
            slotProps={{ htmlInput: { min: 0 } }}
            onKeyDown={(event) => {
              if (event?.key === "-" || event?.key === "+") {
                event.preventDefault();
              }
            }}
            onChange={(e) =>
              saleDispatch({
                type: "SET_FIELD",
                fieldName: "price",
                value: e.currentTarget.value,
              })
            }
          />
        </Grid2>

        {/* saleSize */}
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <TextField
            label="Sale Size"
            type="number"
            variant="outlined"
            fullWidth
            helperText={
              productInfoMap[saleInfo.productId]
                ? `Currently ${productInfoMap[saleInfo.productId].stock} in stock.`
                : ""
            }
            value={saleInfo.saleSize || ""}
            onChange={(e) => {
              saleDispatch({
                type: "SET_FIELD",
                fieldName: "saleSize",
                value: e.target.value,
              });
            }}
          />
        </Grid2>

        <Grid2 size={{ xs: 12, sm: 6 }}>
          <DatePicker
            sx={{ width: "100%" }}
            label="Sale Date"
            format="DD-MM-YYYY" // Setting the desired date format
            value={dayjs(saleInfo.saleDate, "DD-MM-YYYY")}
            onChange={(newValue) => {
              saleDispatch({
                type: "SET_FIELD",
                fieldName: "saleDate",
                value: newValue?.format("DD-MM-YYYY") ?? "",
              });
            }}
          />
        </Grid2>

        <Grid2 size={{ xs: 12 }}>
          <TextField
            label="Receipt Id"
            variant="outlined"
            fullWidth
            value={saleInfo.receiptId}
            onChange={(e) =>
              saleDispatch({
                type: "SET_FIELD",
                fieldName: "receiptId",
                value: e.currentTarget.value,
              })
            }
          />
        </Grid2>

        <Grid2 size={{ xs: 12 }}>
          <TextField
            label="Other Information"
            variant="outlined"
            fullWidth
            multiline
            rows={4} // Adjust the number of rows for the text area
            value={saleInfo.otherInfo}
            onChange={(e) =>
              saleDispatch({
                type: "SET_FIELD",
                fieldName: "otherInfo",
                value: e.target.value,
              })
            }
          />
        </Grid2>
        <Grid2 container spacing={2} size={{ xs: 12 }}>
          <Grid2 size={{ xs: 6 }}>
            <Button
              variant="outlined"
              color="secondary"
              fullWidth
              onClick={() => {
                nav(-1);
              }}
            >
              Cancel
            </Button>
          </Grid2>
          <Grid2 size={{ xs: 6 }}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                dispatch(createSale(saleInfo));
                nav(-1);
              }}
            >
              Save
            </Button>
          </Grid2>
        </Grid2>
      </Grid2>
    </Box>
  );
};
