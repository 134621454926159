import {
  GridColDef,
  GridRowParams,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "../store/store";
import { Dispatch } from "react";
import { Link, Tooltip } from "@mui/material";
import { deleteSales } from "../store/asyncThunk/productThunk";

import { useNavigate } from "react-router-dom";
import { useGetDialog } from "./useGetDialog";
import { useRequestDataIfNeeded } from "./useRequestDataIfNeeded";
import { routesList } from "../constants";

export const useSalesDataGridInfo = () => {
  const productsInfoMap = useAppSelector((state) => state.productInfoMap);
  const sales = useAppSelector((state) => state.sales);
  const deleteDialog = useGetDialog();
  const dispatch = useAppDispatch();
  const nav = useNavigate();

  useRequestDataIfNeeded(["products", "sales"]);

  const columns: GridColDef[] = [
    {
      field: "productId",
      headerName: "Product Sold",
      width: 150,
      description: "Emri i produktit te shitur",
      valueGetter: (value) => {
        return productsInfoMap[value]?.name ?? "";
      },
      renderCell: (params) => {
        return (
          <Tooltip title={productsInfoMap[params.row.productId]?.name}>
            <Link
              underline="hover"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                nav(routesList.products + `/${params.row.productId}`);
              }}
            >
              {productsInfoMap[params.row.productId]?.name}
            </Link>
          </Tooltip>
        );
      },
    },
    {
      field: "receiptId",
      headerName: "Receipt Id",
      type: "string",
      minWidth: 120,
      align: "left",
      headerAlign: "left",
      description: "Numri i fatures",
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.receiptId}>
            <span>{params.row.receiptId}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "saleDate",
      headerName: "Sale Date",
      type: "date",
      width: 100,
      align: "left",
      headerAlign: "left",
      valueGetter: (value: string) => {
        return new Date(value);
      },
      description: "Data ne te cilen eshte bere shitja",
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.saleDate}>
            <span>{params.row.saleDate}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "saleSize",
      headerName: "Sale Size",
      type: "number",
      minWidth: 50,
      align: "left",
      headerAlign: "left",
      description: "Sa produkt eshte shitur",
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.saleSize}>
            <span>{params.row.saleSize}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "price",
      headerName: "Sale Price",
      headerAlign: "left",
      type: "number",
      width: 120,
      align: "left",
      description: "Cmimi i shitjes",
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.price}>
            <span>{params.row.price}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "otherInfo",
      headerName: "Other Info",
      width: 380,
      flex: 1,
      description: "Informacione ekstra mbi shitjen",
      renderCell: (params) => (
        <Tooltip
          title={params.value || ""}
          arrow
          placement="bottom"
          sx={{ maxWidth: 10 }}
        >
          <span
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {params.value}
          </span>
        </Tooltip>
      ),
    },
  ];

  const handleDeleteRows = (
    rows: GridRowSelectionModel,
    setSelectedRows: Dispatch<React.SetStateAction<GridRowSelectionModel>>
  ) => {
    deleteDialog.update({
      title: `Are you sure you want to delete ${rows.length} Sales`,
      primaryButton: {
        label: "Yes",
        onClick: () => {
          dispatch(deleteSales(rows as string[]));
          deleteDialog.close();
          setSelectedRows([]);
        },
      },
      secondaryButton: {
        label: "No",
        onClick: () => {
          deleteDialog.close();
        },
      },
    });
    deleteDialog.toggleDialog();
  };

  const handleAddInstance = () => {
    nav(routesList.addSale);
  };

  const handleRowDoubleClick = (params: GridRowParams) => {
    nav(routesList.sales + `/${params.row.id}`);
  };

  return {
    gridProps: {
      rows: sales.list,
      columns: columns,
      handleDeleteRows: handleDeleteRows,
      handleAddInstance: handleAddInstance,
      handleRowDoubleClick: handleRowDoubleClick,
      csvFileName: "Sales",
    },
    dialog: deleteDialog,
  };
};
