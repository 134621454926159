import {
  GridColDef,
  GridRowParams,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "../store/store";
import { Dispatch } from "react";
import { Box, Link } from "@mui/material";
import { deleteBrands } from "../store/asyncThunk/productThunk";
import { useGetDialog } from "./useGetDialog";
import { useNavigate } from "react-router-dom";
import { useRequestDataIfNeeded } from "./useRequestDataIfNeeded";
import { routesList } from "../constants";

export const useBrandsDataGridInfo = () => {
  const brands = useAppSelector((state) => state.brands);
  const deleteDialog = useGetDialog();
  const dispatch = useAppDispatch();
  const nav = useNavigate();

  useRequestDataIfNeeded(["brands"]);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      description: "Emri i brandit",
      renderCell: (params) => (
        <Link
          underline="hover"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            nav(routesList.brands + `/${params.row.id}`);
          }}
        >
          {params.row.name}
        </Link>
      ),
    },
    {
      field: "picture",
      headerName: "Brand Picture",
      flex: 1,
      description: "Fotoja e brandit",
      renderCell: (params) => (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <img
            style={{ objectFit: "cover", height: "50px", userSelect: "none" }}
            src={params.row.pictureUrl}
            alt="Cannot load"
            draggable={false}
          />
        </Box>
      ),
    },
  ];

  const handleDeleteRows = (
    rows: GridRowSelectionModel,
    setSelectedRows: Dispatch<React.SetStateAction<GridRowSelectionModel>>
  ) => {
    deleteDialog.update({
      title: `Are you sure you want to delete ${rows.length} Brands`,
      primaryButton: {
        label: "Yes",
        onClick: () => {
          dispatch(deleteBrands(rows as string[]));
          deleteDialog.close();
          setSelectedRows([]);
        },
      },
      secondaryButton: {
        label: "No",
        onClick: () => {
          deleteDialog.close();
        },
      },
    });
    deleteDialog.toggleDialog();
  };

  const handleAddInstance = () => {
    nav(routesList.addBrand);
  };

  const handleRowDoubleClick = (params: GridRowParams) => {
    nav(routesList.brands + `/${params.row.id}`);
  };

  return {
    gridProps: {
      rows: brands.list,
      columns: columns,
      handleDeleteRows: handleDeleteRows,
      handleAddInstance: handleAddInstance,
      handleRowDoubleClick: handleRowDoubleClick,
      csvFileName: "Brands",
    },
    dialog: deleteDialog,
  };
};
