import React, { useMemo, useReducer } from "react";
import { RestockingData } from "../../../packages/cmsapi";
import { Box, Button, Grid2, TextField, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { createRestocking } from "../../../store/asyncThunk/productThunk";
import { useNavigate } from "react-router-dom";
import { RestockingReducer } from "./RestockingReducer";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { VirtualizedAutocomplete } from "../../Common";
import { useRequestDataIfNeeded } from "../../../hooks/useRequestDataIfNeeded";

const emptyRestockingState = { receiptId: "" } as RestockingData;

export const AddRestocking = () => {
  const products = useAppSelector((state) => state.products);
  const [restockInfo, restockDispatch] = useReducer(
    RestockingReducer,
    emptyRestockingState
  );
  const nav = useNavigate();
  const dispatch = useAppDispatch();

  useRequestDataIfNeeded(["restockings", "products"]);

  const virtualizedProductList = useMemo(() => {
    return products.list.filter((product) => !product.isDeleted);
  }, [products.list]);

  return (
    <Box
      sx={{
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Typography variant="h4" gutterBottom>
        Add Restocking
      </Typography>

      <Grid2 container spacing={2} justifyContent={"center"}>
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <VirtualizedAutocomplete
            options={virtualizedProductList}
            setSelected={(suggestions) => {
              restockDispatch({
                type: "SET_FIELD",
                fieldName: "productId",
                value: suggestions[0].id,
              });
            }}
            selected={restockInfo.productId ? [restockInfo.productId] : []}
            isMultiple={false}
            label="Bought Product"
          />
        </Grid2>
        {/* Price */}
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <TextField
            label="Price"
            type="number"
            variant="outlined"
            fullWidth
            value={restockInfo.price || ""}
            slotProps={{ htmlInput: { min: 0 } }}
            onKeyDown={(event) => {
              if (event?.key === "-" || event?.key === "+") {
                event.preventDefault();
              }
            }}
            onChange={(e) =>
              restockDispatch({
                type: "SET_FIELD",
                fieldName: "price",
                value: e.currentTarget.value,
              })
            }
          />
        </Grid2>

        {/* restockSize */}
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <TextField
            label="Restock Size"
            type="number"
            variant="outlined"
            fullWidth
            value={restockInfo.restockSize || ""}
            onChange={(e) => {
              restockDispatch({
                type: "SET_FIELD",
                fieldName: "restockSize",
                value: e.target.value,
              });
            }}
          />
        </Grid2>

        <Grid2 size={{ xs: 12, sm: 6 }}>
          <DatePicker
            sx={{ width: "100%" }}
            label="Restock Date"
            format="DD-MM-YYYY" // Setting the desired date format
            value={dayjs(restockInfo.restockDate, "DD-MM-YYYY")}
            onChange={(newValue) => {
              restockDispatch({
                type: "SET_FIELD",
                fieldName: "restockDate",
                value: newValue?.format("DD-MM-YYYY") ?? "",
              });
            }}
          />
        </Grid2>

        <Grid2 size={{ xs: 12 }}>
          <TextField
            label="Receipt Id"
            variant="outlined"
            fullWidth
            value={restockInfo.receiptId}
            onChange={(e) =>
              restockDispatch({
                type: "SET_FIELD",
                fieldName: "receiptId",
                value: e.currentTarget.value,
              })
            }
          />
        </Grid2>

        <Grid2 size={{ xs: 12 }}>
          <TextField
            label="Other Information"
            variant="outlined"
            fullWidth
            multiline
            rows={4} // Adjust the number of rows for the text area
            value={restockInfo.otherInfo}
            onChange={(e) =>
              restockDispatch({
                type: "SET_FIELD",
                fieldName: "otherInfo",
                value: e.target.value,
              })
            }
          />
        </Grid2>
        <Grid2 container spacing={2} size={{ xs: 12 }}>
          <Grid2 size={{ xs: 6 }}>
            <Button
              variant="outlined"
              color="secondary"
              fullWidth
              onClick={() => {
                nav(-1);
              }}
            >
              Cancel
            </Button>
          </Grid2>
          <Grid2 size={{ xs: 6 }}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                dispatch(createRestocking(restockInfo));
                nav(-1);
              }}
            >
              Save
            </Button>
          </Grid2>
        </Grid2>
      </Grid2>
    </Box>
  );
};
