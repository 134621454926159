import {
  SlideshowData,
  RestockingData,
  PrivateProductData,
  PrivateBrandData,
  SaleData,
  ProductTypeData,
} from "../../packages/cmsapi";
import {
  addPicturesToProduct,
  createBrand,
  createProduct,
  createProductType,
  createRestocking,
  createSale,
  createSlideshow,
  deleteBrand,
  deleteBrands,
  deleteProduct,
  deleteProductPictures,
  deleteProducts,
  deleteProductTypes,
  deleteRestockings,
  deleteSales,
  deleteSlideshows,
  getBrands,
  getProducts,
  getProductTypes,
  getRestockings,
  getSales,
  getSlideshows,
  reorderProductPictures,
  updateBrand,
  updateProduct,
  updateProductType,
  updateRestocking,
  updateSale,
  updateSlideshow,
} from "../asyncThunk/productThunk";
import { createSlice } from "@reduxjs/toolkit";

export enum State {
  Idle,
  Pending,
  Fulfilled,
  Failed,
}

interface IMultipleDataLoadable<T> {
  list: T[];
  dataState: State;
  error?: any;
}

// interface ISingleDataLoadable<T> {
//   data: T;
//   dataState: State;
//   error?: any;
// }

export interface IProductInfoMap {
  [productId: string]: PrivateProductData;
}

export interface IBrandInfoMap {
  [brandId: string]: PrivateBrandData;
}

export interface ISaleInfoMap {
  [saleId: string]: SaleData;
}

export interface IRestockingInfoMap {
  [restockingId: string]: RestockingData;
}

export interface IProductTypesInfoMap {
  [productTypeId: string]: ProductTypeData;
}

interface IState {
  products: IMultipleDataLoadable<PrivateProductData>;
  productInfoMap: IProductInfoMap;
  brands: IMultipleDataLoadable<PrivateBrandData>;
  brandInfoMap: IBrandInfoMap;
  slideshows: IMultipleDataLoadable<SlideshowData>;
  sales: IMultipleDataLoadable<SaleData>;
  saleInfoMap: ISaleInfoMap;
  restockings: IMultipleDataLoadable<RestockingData>;
  restockingInfoMap: IRestockingInfoMap;
  productTypes: IMultipleDataLoadable<ProductTypeData>;
  productTypesInfoMap: IProductTypesInfoMap;
}

const initialState: IState = {
  products: { list: [], dataState: State.Idle },
  productInfoMap: {},
  brands: { list: [], dataState: State.Idle },
  brandInfoMap: {},
  slideshows: { list: [], dataState: State.Idle },
  sales: { list: [], dataState: State.Idle },
  saleInfoMap: {},
  restockings: { list: [], dataState: State.Idle },
  restockingInfoMap: {},
  productTypes: { list: [], dataState: State.Idle },
  productTypesInfoMap: {},
};

const VideomobileSlice = createSlice({
  name: "Product",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.fulfilled, (state, action) => {
        if (action.payload) {
          state.products.list = action.payload;
          action.payload.forEach((product) => {
            state.productInfoMap[product.id] = product;
          });
          state.products.dataState = State.Fulfilled;
        }
      })
      .addCase(getProducts.pending, (state) => {
        state.products.dataState = State.Pending;
      })
      .addCase(getProducts.rejected, (state) => {
        state.products.list = [];
        state.products.dataState = State.Failed;
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        if (action.payload) {
          const updatedProduct = action.payload as PrivateProductData;
          state.productInfoMap[updatedProduct.id] = updatedProduct;
          const index = state.products.list.findIndex(
            (product) => product.id === updatedProduct.id
          );
          if (index !== -1) {
            state.products.list[index] = updatedProduct;
          }
        }
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        const deletedProductId = action.meta.arg;
        delete state.productInfoMap[deletedProductId];
        state.products.list = state.products.list
          .filter((product) => product.id !== deletedProductId)
          .map((product) => ({
            ...product,
            suggestions: product.suggestions?.filter((suggestion) => {
              return suggestion !== deletedProductId;
            }),
          }));
      })
      .addCase(deleteProducts.fulfilled, (state, action) => {
        const allDeletedProductIds = action.meta.arg;
        const hardDeletedProductIds = action.payload;
        state.products.list = state.products.list
          .filter((product) => {
            if (hardDeletedProductIds?.includes(product.id)) {
              delete state.productInfoMap[product.id];
              return false;
            }
            return true;
          })
          .map((product) => {
            if (allDeletedProductIds?.includes(product.id)) {
              state.productInfoMap[product.id].isDeleted = true;
              return { ...product, isDeleted: true };
            }
            const productWithUpdatedSuggestions = {
              ...product,
              suggestions: product.suggestions?.filter((suggestion) => {
                return !allDeletedProductIds.includes(suggestion);
              }),
            };
            state.productInfoMap[productWithUpdatedSuggestions.id] =
              productWithUpdatedSuggestions;

            return productWithUpdatedSuggestions;
          });
      })
      .addCase(getBrands.fulfilled, (state, action) => {
        if (action.payload) {
          state.brands.list = action.payload;
          action.payload.forEach(
            (brand) => (state.brandInfoMap[brand.id] = brand)
          );
          state.brands.dataState = State.Fulfilled;
        }
      })
      .addCase(getBrands.pending, (state) => {
        state.brands.dataState = State.Pending;
      })
      .addCase(getBrands.rejected, (state) => {
        state.brands.list = [];
        state.brands.dataState = State.Failed;
      })
      .addCase(deleteBrand.fulfilled, (state, action) => {
        const deletedBrandId = action.meta.arg;
        delete state.brandInfoMap[deletedBrandId];
        state.brands.list = state.brands.list.filter(
          (brand) => brand.id !== deletedBrandId
        );
      })
      .addCase(deleteBrands.fulfilled, (state, action) => {
        state.brands.list = state.brands.list.filter((brand) => {
          if (action.payload?.includes(brand.id)) {
            delete state.brandInfoMap[brand.id];
            return false;
          } else if (action.meta.arg.includes(brand.id)) {
            brand.isDeleted = true;
            state.brandInfoMap[brand.id] = {
              ...state.brandInfoMap[brand.id],
              isDeleted: true,
            };
          }
          return true;
        });
        state.products.list = state.products.list.map((product) => {
          if (action.meta.arg.includes(product.brandId ?? "")) {
            return { ...product, brandId: undefined };
          }
          return product;
        });
      })
      .addCase(getSlideshows.fulfilled, (state, action) => {
        if (action.payload) {
          state.slideshows.list = action.payload;
          state.slideshows.dataState = State.Fulfilled;
        }
      })
      .addCase(getSlideshows.pending, (state) => {
        state.slideshows.dataState = State.Pending;
      })
      .addCase(deleteSlideshows.fulfilled, (state, action) => {
        state.slideshows.list = state.slideshows.list.filter(
          (slideshow) => !action.meta.arg.includes(slideshow.id)
        );
      })
      .addCase(getSales.fulfilled, (state, action) => {
        if (action.payload) {
          state.sales.list = action.payload;
          state.sales.list.forEach((sale) => {
            state.saleInfoMap[sale.id] = sale;
          });
          state.sales.dataState = State.Fulfilled;
        }
      })
      .addCase(getSales.pending, (state) => {
        state.sales.dataState = State.Pending;
      })
      .addCase(deleteSales.fulfilled, (state, action) => {
        //TODO: update productInfoMap and products.list state with correct stock
        state.sales.list = state.sales.list.filter(
          (sale) => !action.meta.arg.includes(sale.id)
        );
        action.meta.arg.forEach(
          (deletedSaleId) => delete state.saleInfoMap[deletedSaleId]
        );
      })
      .addCase(getRestockings.fulfilled, (state, action) => {
        if (action.payload) {
          state.restockings.list = action.payload;
          state.restockings.list.forEach((restocking) => {
            state.restockingInfoMap[restocking.id] = restocking;
          });
          state.restockings.dataState = State.Fulfilled;
        }
      })
      .addCase(getRestockings.pending, (state) => {
        state.restockings.dataState = State.Pending;
      })
      .addCase(deleteRestockings.fulfilled, (state, action) => {
        //TODO: update productInfoMap and products.list state with correct stock
        state.restockings.list = state.restockings.list.filter(
          (restocking) => !action.meta.arg.includes(restocking.id)
        );
        action.meta.arg.forEach(
          (deletedRestockingId) =>
            delete state.restockingInfoMap[deletedRestockingId]
        );
      })
      .addCase(createProduct.fulfilled, (state, action) => {
        if (action.payload) {
          const newProduct = action.payload as PrivateProductData;
          state.products.list.push(newProduct);
          state.productInfoMap[newProduct.id] = newProduct;
        }
      })
      .addCase(createBrand.fulfilled, (state, action) => {
        if (action.payload) {
          const newBrand = action.payload as PrivateBrandData;
          state.brands.list.push(newBrand);
          state.brandInfoMap[newBrand.id] = newBrand;
        }
      })
      .addCase(updateBrand.fulfilled, (state, action) => {
        if (action.payload) {
          const updatedBrand = action.payload as PrivateBrandData;
          state.brandInfoMap[updatedBrand.id] = updatedBrand;
          const index = state.brands.list.findIndex(
            (brand) => brand.id === updatedBrand.id
          );
          if (index !== -1) {
            state.brands.list[index] = updatedBrand;
          }
        }
      })
      .addCase(createSlideshow.fulfilled, (state, action) => {
        if (action.payload) {
          state.slideshows.list.push(action.payload as SlideshowData);
        }
      })
      .addCase(createSale.fulfilled, (state, action) => {
        if (action.payload) {
          const saleData = action.payload;
          const soldProduct = saleData.product;
          if (soldProduct) {
            state.productInfoMap[soldProduct.id] = soldProduct;
            const index = state.products.list.findIndex(
              (product) => product.id === soldProduct.id
            );
            state.products.list[index] = soldProduct;
          }
          state.sales.list.push(saleData);
          state.saleInfoMap[saleData.id] = saleData;
        }
      })
      .addCase(createRestocking.fulfilled, (state, action) => {
        if (action.payload) {
          const restockData = action.payload;
          const restockedProduct = restockData.product;
          if (restockedProduct) {
            state.productInfoMap[restockedProduct.id] = restockedProduct;
            const index = state.products.list.findIndex(
              (product) => product.id === restockedProduct.id
            );
            state.products.list[index] = restockedProduct;
          }
          state.restockings.list.push(restockData);
          state.restockingInfoMap[restockData.id] = restockData;
        }
      })
      .addCase(updateSale.fulfilled, (state, action) => {
        if (action.payload) {
          const updatedSale = action.payload;
          const soldProduct = updatedSale.product;
          if (soldProduct) {
            state.productInfoMap[soldProduct.id] = soldProduct;
            const index = state.products.list.findIndex(
              (product) => product.id === soldProduct.id
            );
            if (index !== -1) {
              state.products.list[index] = soldProduct;
            }
          }

          state.saleInfoMap[updatedSale.id] = updatedSale;
          const index = state.sales.list.findIndex(
            (sale) => sale.id === updatedSale.id
          );
          if (index !== -1) {
            state.sales.list[index] = updatedSale;
          }
        }
      })
      .addCase(updateRestocking.fulfilled, (state, action) => {
        if (action.payload) {
          const updatedRestocking = action.payload;
          state.restockingInfoMap[updatedRestocking.id] = updatedRestocking;
          const restockedProduct = updatedRestocking.product;
          if (restockedProduct) {
            state.productInfoMap[restockedProduct.id] = restockedProduct;
            const index = state.products.list.findIndex(
              (product) => product.id === restockedProduct.id
            );
            if (index !== -1) {
              state.products.list[index] = restockedProduct;
            }
          }
          const index = state.restockings.list.findIndex(
            (restocking) => restocking.id === updatedRestocking.id
          );
          if (index !== -1) {
            state.restockings.list[index] = updatedRestocking;
          }
        }
      })
      .addCase(updateSlideshow.fulfilled, (state, action) => {
        if (action.payload) {
          const updatedSlideshow = action.payload;
          const index = state.slideshows.list.findIndex(
            (slideshow) => slideshow.id === updatedSlideshow.id
          );
          if (index !== -1) {
            state.slideshows.list[index] = updatedSlideshow;
          }
        }
      })
      .addCase(getProductTypes.fulfilled, (state, action) => {
        if (action.payload) {
          state.productTypes.list = action.payload;
          action.payload.forEach(
            (productType) =>
              (state.productTypesInfoMap[productType.id] = productType)
          );
          state.productTypes.dataState = State.Fulfilled;
        }
      })
      .addCase(createProductType.fulfilled, (state, action) => {
        if (action.payload) {
          const productTypeData = action.payload;
          state.productTypes.list.push(productTypeData);
          state.productTypesInfoMap[productTypeData.id] = productTypeData;
        }
      })
      .addCase(deleteProductTypes.fulfilled, (state, action) => {
        const deletedProductTypes = action.meta.arg;

        state.productTypes.list = state.productTypes.list.filter(
          (productType) => !deletedProductTypes.includes(productType.id)
        );
        deletedProductTypes.forEach(
          (deletedProductTypeId) =>
            delete state.productTypesInfoMap[deletedProductTypeId]
        );

        state.products.list = state.products.list.map((product) => ({
          ...product,
          types: product.types?.filter(
            (typeId) => !deletedProductTypes.includes(typeId)
          ),
        }));

        state.products.list.forEach((product) => {
          state.productInfoMap[product.id] = {
            ...product,
            types: product.types?.filter(
              (typeId) => !deletedProductTypes.includes(typeId)
            ),
          };
        });
      })
      .addCase(updateProductType.fulfilled, (state, action) => {
        if (action.payload) {
          const updatedProductType = action.payload;
          state.productTypesInfoMap[updatedProductType.id] = updatedProductType;
          const index = state.productTypes.list.findIndex(
            (productType) => productType.id === updatedProductType.id
          );
          if (index !== -1) {
            state.productTypes.list[index] = updatedProductType;
          }
        }
      })
      .addCase(addPicturesToProduct.fulfilled, (state, action) => {
        if (action.payload) {
          const updatedProductId = action.meta.arg.productId;
          const newPicturesUrl = action.payload;
          state.productInfoMap[action.meta.arg.productId] = {
            ...state.productInfoMap[action.meta.arg.productId],
            picturesUrl: newPicturesUrl,
          };
          const index = state.products.list.findIndex(
            (product) => product.id === updatedProductId
          );

          if (index !== -1) {
            state.products.list[index] = {
              ...state.products.list[index],
              picturesUrl: newPicturesUrl,
            };
          }
        }
      })
      .addCase(reorderProductPictures.fulfilled, (state, action) => {
        const updatedProductId = action.meta.arg.productId;
        const updatedPictures = action.meta.arg.pictureUrls;
        state.productInfoMap[updatedProductId] = {
          ...state.productInfoMap[updatedProductId],
          picturesUrl: updatedPictures,
        };
        const index = state.products.list.findIndex(
          (product) => product.id === updatedProductId
        );
        if (index !== -1) {
          state.products.list[index] = {
            ...state.products.list[index],
            picturesUrl: updatedPictures,
          };
        }
      })
      .addCase(deleteProductPictures.fulfilled, (state, action) => {
        const updatedProductId = action.meta.arg.productId;
        const updatedPictures = state.productInfoMap[
          updatedProductId
        ].picturesUrl.filter(
          (pictureUrl) => !action.meta.arg.pictureUrls.includes(pictureUrl)
        );

        state.productInfoMap[updatedProductId] = {
          ...state.productInfoMap[updatedProductId],
          picturesUrl: updatedPictures,
        };

        const index = state.products.list.findIndex(
          (product) => product.id === updatedProductId
        );

        if (index !== -1) {
          state.products.list[index] = {
            ...state.products.list[index],
            picturesUrl: updatedPictures,
          };
        }
      });
  },
});

export default VideomobileSlice.reducer;
